import { WithFeatureFlags, getEnabledFeatureFlags } from '@utils/featureFlags';
import { trpc } from '@utils/trpc';
import Head from 'next/head';
import React, { useState } from 'react';
import { PrimaryColumn } from '~/components/layout/';
import { DashboardGrid } from '~/components/pages/dashboard/DashboardGrid';
import { DashboardHeader } from '~/components/pages/dashboard/DashboardHeader';
import { DefaultLayout } from '~/layout/DefaultLayout';
import { NextPageWithLayout } from '~/pages/_app';
import { JobIndexContextProvider } from '~/react_context/JobIndexContext';
import { appRouter } from '~/server/routers/_app';
import { Role } from '@prisma/client';
import { getAuth } from '@clerk/nextjs/server';
import { NextRequest } from 'next/server';

const DashboardIndex: NextPageWithLayout<WithFeatureFlags> = () => {
	const { data: branches } = trpc.branch.readAll.useQuery();
	const [activeBranches, setActiveBranches] = useState<string[]>([]);

	return (
		<>
			<Head>
				<title>Dashboard | Glazier</title>
			</Head>

			<PrimaryColumn>
				<DashboardHeader branches={branches ?? undefined} activeBranches={activeBranches} setActiveBranches={setActiveBranches} />
				<DashboardGrid activeBranchIds={activeBranches.map((branchId) => Number(branchId))} />
			</PrimaryColumn>
		</>
	);
};

DashboardIndex.getLayout = function (page: React.ReactElement, props: WithFeatureFlags) {
	return (
		<DefaultLayout backgroundColor="bg-gray-100" featureFlags={props.featureFlags}>
			<JobIndexContextProvider>{page}</JobIndexContextProvider>
		</DefaultLayout>
	);
};

export const getServerSideProps = async (ctx: { req: NextRequest }) => {
	const { userId } = getAuth(ctx.req);

	// @ts-expect-error We need this information to fetch the user through server side and to prevent errors in the meantime
	const caller = appRouter.createCaller({
		businessId: -1,
		clerkUserId: userId,
		role: Role.FULL,
	});

	if (!userId) {
		return {
			props: getEnabledFeatureFlags(ctx.req),
		};
	}

	const user = await caller.context.forUser({ clerkUserId: userId });

	if (user.role === Role.HELPER) {
		return {
			redirect: {
				destination: '/404',
			},
		};
	}

	return {
		props: getEnabledFeatureFlags(ctx.req),
	};
};

export default DashboardIndex;
