import { useRouter } from 'next/router';
import { ButtonGroup } from '~/components/utils/buttons';

export const DashboardPageToggle = () => {
	const router = useRouter();

	const isAnalytics = router.pathname.toLowerCase().includes('analytics');
	const isCalendar = router.pathname.toLowerCase().includes('calendar');
	const isCommercialMilestones = router.pathname.toLowerCase().includes('commercial-milestones');

	return (
		<ButtonGroup
			initActiveButton={isAnalytics ? 'Analytics' : isCalendar ? 'Calendar' : isCommercialMilestones ? 'Work in Progress' : 'Operations'}
			buttons={[
				{
					label: 'Operations',
					onClick: () => router.push('/'),
				},
				{ label: 'Analytics', onClick: () => router.push('/analytics') },
				{ label: 'Calendar', onClick: () => router.push('/calendar') },
				{ label: 'Work in Progress', onClick: () => router.push('/commercial-milestones') },
			]}
			buttonClasses="text-base font-medium whitespace-nowrap"
		/>
	);
};
